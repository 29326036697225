.advertiser {

  .advertiser-header {
    position: relative;
    .föreningar {
      position: absolute;
      left: 70%;
      top: 35%;
    }
    .företag {
      position: absolute;
      left: 80%;
      top: 35%;
    }
    .kyrkor {
      position: absolute;
      left: 80%;
      top: 35%;
    }
    .se {
      position: absolute;
      left: 75%;
      top: 35%;
    }
  }
  .advertiser-item {
    max-height: 200px;
    cursor: pointer;
    .advertiser-img {
      .advertiser-item-img {
        max-width: 160px;
        max-height: 50px;
      }
    }
  }
  .sections {
    .section-btn {
      border: 0px solid black;
      padding: 10px;
      border-radius: 5%;
      color: black;
      background-color: white;
      font-weight: bold;
      text-transform: uppercase;
      &:hover {
        background-color: #ffffff;
      }
    }
    .active {
      background-color: #ffffff;
    }
  }
}

.ads .ad-type {
  color: #7e7e7e;
  font-size: 24px;
  font-weight: bold;
}
.ads .association-ad,
.ads .company-ad {
  background-color: #f5f5f5;
  padding: 27px;
}
.ads .company-ad,
.ads .association-ads {
  margin: 0 -27px;
}
.ads .ads-right-img {
  height: 15rem;
}
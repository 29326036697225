.resa {
  .resa-header {
    position: relative;
  }

  .events-header-title {
    position: absolute;
    left: 80%;
    top: 35%;
  }
  .resa-item {
    cursor: pointer;
  }
  
}

.weather {
  min-height: 2120px;
  overflow: auto;

  .weather-header {
    position: relative;
    .weather-header-title {
      position: absolute;
      left: 85%;
      top: 35%;
    }
  }
  .weather-item {
    // max-width: fit-content;
    .weather-body {
      .weather-icon {
        height: 100px;
        width: 100px;
      }
      .more {
        background-color: white;
      }
      .weather-safety {
        width: 120px;
      }
    }
    .weather-hours {
      // max-width: 880px;
      .weather-icon {
        height: 72px;
        width: 72px;
      }
      .wind-icon {
        font-weight: bolder !important;
      }
      
      .weather-hours-titles {
        max-width: 25%;
        border-bottom: 1px solid black;
        border-top: 1px solid black;
        font-weight: bold;
        padding: 10px 0 10px 0;
        .weather-hour-title {
          font-size: 26px;
          max-width: min-content;
        }
      }
      tbody .weather-hour,
      tbody tr {
        font-size: 26px;
        cursor: pointer;
        // padding: 10px;
        // minwidth: 100px;
      }
    }
  }
}

.register .events-header {
  position: relative;
}
.register .events-header .events-header-title {
  position: absolute;
  left: 70%;
  top: 35%;
}
.register .register-form {
  max-width: 90%;
}
.register .register-form .form-input {
  max-width: 75%;
  font-size: x-large !important;
  font-style: italic !important;
}
.register .register-form .register-btn {
  background-color: #989898;
}/*# sourceMappingURL=Register.css.map */
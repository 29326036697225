.resa .resa-header {
  position: relative;
}
.resa .events-header-title {
  position: absolute;
  left: 80%;
  top: 35%;
}
.resa .resa-item {
  cursor: pointer;
}/*# sourceMappingURL=Resa.css.map */
.news {
  div {
    width:calc(50% - 10px);
    float:left;
    div {
      width: 100%;
    }
  }
  div:nth-child(odd) {
    margin-right: 20px;
  }

}

.residence-for-sale {


  .residence-item-icon-div {
    margin-right: 20px;
    .residence-item-icon {
      font-size: 30px;
    }
  }
  .residence-img {
    max-width: 150px;
  }
}

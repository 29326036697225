.lunch {

  .customer-img {
      img {
        max-height: 30px;
        max-width: 190px;
      }    
  }

}

.family-news {
  .family-news-header {
    position: relative;
    .family-news-title {
      position: absolute;
      left: 65%;
      top: 35%;
    }
  }
  .family-news-item {
    .family-news-title-body {
      .family-news-body {
         display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    .family-news-img {
      max-width: 228px;
    }
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: 5;
  justify-content: center;
  position: relative;

  .header-img {
    align-self: center;
    width: 400px;
    max-width: 95%;
    img {
      margin: 30px 0 0px 0;
    }
  }
}

/* Menu Start */

.dropdown-menu {
  border-color: #000000!important;
}

.navbar .megamenu{ padding: 1rem; }

.megamenu {

  li:first-child {
    padding-left: 5px;
  }

  a {
    padding-left: 5px!important;
  }
  a:hover {
    background-color: #F1F1F1;
  }

  border-color: black;

}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {

  .navbar .has-megamenu{position:static!important;}
  .navbar .megamenu{left:0; right:0; width:100%; margin-top:0;  }

}	
/* ============ desktop view .end// ============ */

/* ============ mobile view ============ */
@media(max-width: 991px){
  .navbar.fixed-top .navbar-collapse, .navbar.sticky-top .navbar-collapse{
    overflow-y: auto;
      max-height: 90vh;
      margin-top:10px;
  }
}
/* ============ mobile view .end// ============ */


/* Menu End */
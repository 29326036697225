.reader-news {
  height: 2141px;
  overflow: auto;
}
.reader-news .lds-roller {
  align-self: center;
}
.reader-news .news {
  min-width: 100%;
}
.reader-news .news .advertiser-news {
  max-width: 100%;
  height: fit-content;
  cursor: pointer;
}
.reader-news .news .advertiser-news .advertiser-news-img {
  width: 100%;
  height: 270px;
}/*# sourceMappingURL=ReaderNews.css.map */
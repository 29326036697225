.right-bar {
  .form-right-bar {
    border: 1px solid black;
    border-radius: 5px;
    .send-in {
      background-color: #989898;
    }
  }
  .lunch-item-right-body {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    .lunch-item-logo-right {
      max-width: 200px;
      max-height: 200px;
    }
  }
}

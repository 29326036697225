.advertiser-details,
.advertiser-details {
  height: 2141px;
  overflow: auto;
}
.advertiser-details .advertiser-name-logo,
.advertiser-details .advertiser-name-logo {
  max-height: 200px;
}
.advertiser-details .advertiser-name-logo .advertiser-logo-img,
.advertiser-details .advertiser-name-logo .advertiser-logo-img {
  width: 100px;
  max-height: 100px;
}
.advertiser-details .advertiser-details-ad .carousel .dot,
.advertiser-details .advertiser-details-ad .carousel .dot {
  width: 15px;
  height: 15px;
}
.advertiser-details .advertiser-details-ad .carousel .carousel-status,
.advertiser-details .advertiser-details-ad .carousel .carousel-status {
  background-color: black;
  font-size: x-large;
  border-radius: 10%;
}
.advertiser-details .advertiser-details-ad .advertiser-details-ad-img,
.advertiser-details .advertiser-details-ad .advertiser-details-ad-img {
  max-width: 100%;
}
.advertiser-details .advertiser-details-body .advertiser-details-body-img,
.advertiser-details .advertiser-details-body .advertiser-details-body-img {
  max-width: 100%;
}
.advertiser-details .advertiser-details-opening-times,
.advertiser-details .advertiser-details-address,
.advertiser-details .advertiser-details-contact,
.advertiser-details .advertiser-details-pages,
.advertiser-details .advertiser-details-opening-times,
.advertiser-details .advertiser-details-address,
.advertiser-details .advertiser-details-contact,
.advertiser-details .advertiser-details-pages {
  background-color: #f5f5f5;
  padding: 27px;
  margin: 0 -27px;
}
.advertiser-details .advertiser-details-pages .advertiser-details-pages-btn,
.advertiser-details .advertiser-details-pages .advertiser-details-pages-btn {
  background-color: #989898;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  border-radius: 5px;
  width: 350px;
}/*# sourceMappingURL=AdvertiserDetails.css.map */
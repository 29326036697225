.ads {
  .ad-type {
    color: #7e7e7e;
    font-size: 24px;
    font-weight: bold;
  }
  .association-ad,
  .company-ad {
    background-color: #f5f5f5;
    padding: 27px;
  }
  .company-ad,
  .association-ads {
    margin: 0 -27px;
  }

  .ads-right-img {
    height: 15rem;
  }
}

.ad-page {
  font-family: "Source Sans Pro";
}
.ad-page .advertiser-name-logo {
  max-height: 200px;
}
.ad-page .advertiser-name-logo .advertiser-logo-img {
  width: 100px;
  max-height: 100px;
}
.ad-page .advertiser-details-ad .carousel .dot {
  width: 10px;
  height: 10px;
}
.ad-page .advertiser-details-ad .carousel .carousel-status {
  background-color: black;
  font-size: x-large;
  border-radius: 10%;
}
.ad-page .advertiser-details-ad .advertiser-details-ad-img {
  max-width: 100%;
}
.ad-page .advertiser-details-body .advertiser-details-body-img {
  max-width: 100%;
}
.ad-page .advertiser-details-opening-times,
.ad-page .advertiser-details-address,
.ad-page .advertiser-details-contact,
.ad-page .advertiser-details-pages {
  background-color: #f5f5f5;
  padding: 20px;
}
.ad-page .advertiser-details-pages .advertiser-details-pages-btn {
  background-color: #989898;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
  border-radius: 5px;
  width: 350px;
}/*# sourceMappingURL=AdPage.css.map */
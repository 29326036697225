.home {
  .advertiser-headline {
    cursor: pointer;
    margin: 0 !important;
    .advertiser-headline-img {
      max-width: 100%;
      aspect-ratio: 16/9;
      object-fit: cover;

    }
    .advertiser-headline-title {
      font-size: 56px !important;
      margin-bottom: 10px;
    }
    .advertiser-headline-body {
      font-size: 34px !important;
    }
  }
  .double-news {
    .advertiser-headline {
      max-width: 49%;
      height: fit-content;
      cursor: pointer;
      .advertiser-headline-img {
        width: 100%;
        margin-bottom: 20px;
      }
      .advertiser-headline-title {
        font-size: 36px !important; 
        margin-bottom: 20px;
      }
      .advertiser-headline-body {
        font-size: 30px !important;
      }
    }
  }
  .advertiser-news-home {
    cursor: pointer;
    margin: 0 !important;
    .advertiser-news-home-img {
      max-width: 268px;
      min-height: 248px;
    }
    .advertiser-news-home-title {
      margin-bottom: 10px;
    }
    .advertiser-news-home-body {
      font-size: 32px !important;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .advertiser-company-ad {
    background-color: #f5f5f5;
    cursor: pointer;
    .type {
      color: #7e7e7e;
      font-size: 24px !important;
    }
    .advertiser-company-ad-body-title {

      .advertiser-company-ad-imgs {
        height: 305px;
        max-width: 305px;
        .carousel-root {
          .slider {
            margin-left: auto;
            margin-right: auto;
          }
          .dot {
            width: 15px;
            height: 15px;
          }
          .carousel-status {
            font-size: x-large;
          }
        }
        .company-ad-slider-imgs,
        .company-ad-img {
          height: 305px;
          min-width: 305px;
        }
      }
    }
    .advertiser-name-logo {
      background-color: white;
      max-height: 63px;
      padding: 10px;
      border-radius: 10px;
      max-width: fit-content;
      .advertiser-logo-img {
        max-width: 190px;
        max-height: 50px;
      }
    }
  }

  .advertiser-association-ad {
    background-color: #f5f5f5;
    cursor: pointer;
    .type {
      color: #7e7e7e;
      font-size: 24px !important;
    }
    .advertiser-association-ad-body-title {
      .advertiser-association-ad-title {
        font-size: 38px !important;
      }
      .advertiser-association-ad-body {
      /*  font-size: 28px !important; */
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .advertiser-association-ad-imgs {
        min-width: 228px;
        max-height: 235px;
        .carousel-root {
          .slider {
            margin-left: auto;
            margin-right: auto;
          }
          .dot {
            width: 15px;
            height: 15px;
          }
          .carousel-status {
            font-size: x-large;
          }
        }
        .association-ad-slider-imgs,
        .association-ad-img {
          max-width: 228px;
          min-height: 235px;
        }
      }
    }
    .advertiser-name-logo {
      background-color: white;
      max-height: 63px;
      padding: 10px;
      border-radius: 10px;
      max-width: fit-content;
      .advertiser-name {
        font-size: 30px !important;
      }
      .advertiser-logo-img {
        max-width: 190px;
        max-height: 50px;
      }
    }
  }
  .advertiser-internal-ad {
    .advertiser-internal-ad-title-body {
      .body {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    .advertiser-internal-ad-img {
      max-width: 228px;
    }
  }
}


.input-images {
    img {
        max-width: 100%;
    }

    .selected {
        img {
            border: 5px solid red;
        }
    }
}
.police-news {

  ul {
    padding: 0;
  }

  a {
    color: black;
  }

  .police-news-item-icon {
    font-size: 20px;
  }
}

.register {
  .events-header {
    position: relative;

    .events-header-title {
      position: absolute;
      left: 70%;
      top: 35%;
    }
  }
  .register-form {
    max-width: 90%;
    .form-input {
      max-width: 75%;
      font-size: x-large !important;
      font-style: italic !important;
    }
    .register-btn {
        background-color: #989898;
    }
  }
}

.reader-news {
  height: 2141px;
  overflow: auto;
  .lds-roller {
    align-self: center;
  }
  .news {
    min-width: 100%;
    .advertiser-news {
      max-width: 100%;
      height: fit-content;
      cursor: pointer;
      .advertiser-news-img {
        width: 100%;
        height: 270px;
      }
    }
  }
}

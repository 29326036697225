.offers {
  .offers-header {
    position: relative;
    .offers-header-title {
      position: absolute;
      left: 70%;
      top: 35%;
    }
  }
  .advertiser-offer-ad {
    background-color: #f5f5f5;
    padding: 27px;
    margin: 0 -27px;
    .advertiser-offer-ad-body-title {
      .advertiser-offer-ad-body {
        max-height: 100px;
        width: 550px;
        // white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .advertiser-offer-ad-imgs {
        .carousel-root {
          .dot {
            width: 15px;
            height: 15px;
          }
          .carousel-status {
            font-size: x-large;
          }
        }
        .offer-ad-slider-imgs,
        .advertiser-offer-ad-img {
          height: 300px;
          max-width: 300px;
        }
      }
    }
    .advertiser-name-logo {
      background-color: white;
      max-height: 200px;
      padding: 10px;
      border-radius: 10px;
      .advertiser-name {
        font-size: 30px !important;
      }
      .advertiser-logo-img {
        width: 50px;
        max-height: 50px;
      }
    }
  }
}

@charset "UTF-8";
.advertiser .advertiser-header {
  position: relative;
}
.advertiser .advertiser-header .föreningar {
  position: absolute;
  left: 70%;
  top: 35%;
}
.advertiser .advertiser-header .företag {
  position: absolute;
  left: 80%;
  top: 35%;
}
.advertiser .advertiser-header .kyrkor {
  position: absolute;
  left: 80%;
  top: 35%;
}
.advertiser .advertiser-header .se {
  position: absolute;
  left: 75%;
  top: 35%;
}
.advertiser .advertiser-item {
  max-height: 200px;
  cursor: pointer;
}
.advertiser .advertiser-item .advertiser-img .advertiser-item-img {
  max-width: 160px;
  max-height: 50px;
}
.advertiser .sections .section-btn {
  border: 0px solid black;
  padding: 10px;
  border-radius: 5%;
  color: black;
  background-color: white;
  font-weight: bold;
  text-transform: uppercase;
}
.advertiser .sections .section-btn:hover {
  background-color: #ffffff;
}
.advertiser .sections .active {
  background-color: #ffffff;
}/*# sourceMappingURL=Advertiser.css.map */
.advertiser-details,
.advertiser-details {
  height: 2141px;
  overflow: auto;

  .advertiser-name-logo {
    max-height: 200px;
    .advertiser-logo-img {
      width: 100px;
      max-height: 100px;
    }
  }
  .advertiser-details-ad {
    .carousel {
      .dot {
        width: 15px;
        height: 15px;
      }
      .carousel-status {
        background-color: black;
        font-size: x-large;
        border-radius: 10%;
      }
    }
    .advertiser-details-ad-img {
      max-width: 100%;
    }
  }
  .advertiser-details-body {
    .advertiser-details-body-img {
      max-width: 100%;
    }
  }
  .advertiser-details-opening-times,
  .advertiser-details-address,
  .advertiser-details-contact,
  .advertiser-details-pages {
    background-color: #f5f5f5;
    padding: 27px;
    margin: 0 -27px;
  }
  .advertiser-details-pages {
    .advertiser-details-pages-btn {
      background-color: #989898;
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding: 10px;
      border-radius: 5px;
      width: 350px;
    }
  }
}

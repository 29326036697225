.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .footer {
        position: sticky;
        top: 100%;
    }

    
}

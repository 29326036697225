.weather {
  min-height: 2120px;
  overflow: auto;
}
.weather .weather-header {
  position: relative;
}
.weather .weather-header .weather-header-title {
  position: absolute;
  left: 85%;
  top: 35%;
}
.weather .weather-item .weather-body .weather-icon {
  height: 100px;
  width: 100px;
}
.weather .weather-item .weather-body .more {
  background-color: white;
}
.weather .weather-item .weather-body .weather-safety {
  width: 120px;
}
.weather .weather-item .weather-hours .weather-icon {
  height: 72px;
  width: 72px;
}
.weather .weather-item .weather-hours .wind-icon {
  font-weight: bolder !important;
}
.weather .weather-item .weather-hours .weather-hours-titles {
  max-width: 25%;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  font-weight: bold;
  padding: 10px 0 10px 0;
}
.weather .weather-item .weather-hours .weather-hours-titles .weather-hour-title {
  font-size: 26px;
  max-width: min-content;
}
.weather .weather-item .weather-hours tbody .weather-hour,
.weather .weather-item .weather-hours tbody tr {
  font-size: 26px;
  cursor: pointer;
}/*# sourceMappingURL=Weather.css.map */
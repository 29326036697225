.pool-temp {
  .events-header {
    position: relative;

    .events-header-title {
      position: absolute;
      left: 65%;
      top: 35%;
    }
  }
  .line {
    clear: both;
    color: black !important;
    height: 10px;
  }
}

.offers .offers-header {
  position: relative;
}
.offers .offers-header .offers-header-title {
  position: absolute;
  left: 70%;
  top: 35%;
}
.offers .advertiser-offer-ad {
  background-color: #f5f5f5;
  padding: 27px;
  margin: 0 -27px;
}
.offers .advertiser-offer-ad .advertiser-offer-ad-body-title .advertiser-offer-ad-body {
  max-height: 100px;
  width: 550px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.offers .advertiser-offer-ad .advertiser-offer-ad-body-title .advertiser-offer-ad-imgs .carousel-root .dot {
  width: 15px;
  height: 15px;
}
.offers .advertiser-offer-ad .advertiser-offer-ad-body-title .advertiser-offer-ad-imgs .carousel-root .carousel-status {
  font-size: x-large;
}
.offers .advertiser-offer-ad .advertiser-offer-ad-body-title .advertiser-offer-ad-imgs .offer-ad-slider-imgs,
.offers .advertiser-offer-ad .advertiser-offer-ad-body-title .advertiser-offer-ad-imgs .advertiser-offer-ad-img {
  height: 300px;
  max-width: 300px;
}
.offers .advertiser-offer-ad .advertiser-name-logo {
  background-color: white;
  max-height: 200px;
  padding: 10px;
  border-radius: 10px;
}
.offers .advertiser-offer-ad .advertiser-name-logo .advertiser-name {
  font-size: 30px !important;
}
.offers .advertiser-offer-ad .advertiser-name-logo .advertiser-logo-img {
  width: 50px;
  max-height: 50px;
}
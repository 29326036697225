.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.app .global-padding {
  padding: 27px;
}

.lines-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.img-max img {
  max-width: 100%;
  min-width: 100%;
}

.img-round {
  border-radius: 5px;
}

.clear_both {
  clear: both;
  float: none !important;
}

.date-format {
  display: inline-block;
}

.date-format:first-letter {
  text-transform: uppercase;
}

.rightSideBar-box {
  border: 1px solid black;
  border-radius: 5px;
  font-family: "Source Sans Pro", sans-serif;
}

.grayBackground {
  background-color: #f5f5f5;
}

.advertiser-name-logo {
  background-color: white;
  max-height: 63px;
  padding: 10px;
  border-radius: 10px;
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.advertiser-name-logo img {
  max-width: 190px;
  max-height: 20px;
}

.page-header {
  position: relative;
}
.page-header .header-img {
  width: 100%;
}
.page-header .header-text {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
  color: white;
}

.fw-superbold {
  font-weight: 900 !important;
}/*# sourceMappingURL=App.css.map */
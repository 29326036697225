.home .advertiser-headline {
  cursor: pointer;
  margin: 0 !important;
}
.home .advertiser-headline .advertiser-headline-img {
  max-width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
}
.home .advertiser-headline .advertiser-headline-title {
  font-size: 56px !important;
  margin-bottom: 10px;
}
.home .advertiser-headline .advertiser-headline-body {
  font-size: 34px !important;
}
.home .double-news .advertiser-headline {
  max-width: 49%;
  height: fit-content;
  cursor: pointer;
}
.home .double-news .advertiser-headline .advertiser-headline-img {
  max-width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
  margin-bottom: 20px;
}
.home .double-news .advertiser-headline .advertiser-headline-title {
  font-size: 36px !important;
  margin-bottom: 20px;
}
.home .double-news .advertiser-headline .advertiser-headline-body {
  font-size: 30px !important;
}
.home .advertiser-news-home {
  cursor: pointer;
  margin: 0 !important;
}
.home .advertiser-news-home .advertiser-news-home-img {
  max-width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
}
.home .advertiser-news-home .advertiser-news-home-title {
  margin-bottom: 10px;
}
.home .advertiser-news-home .advertiser-news-home-body {
  font-size: 32px !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.home .advertiser-company-ad {
  background-color: #f5f5f5;
  cursor: pointer;
}
.home .advertiser-company-ad .type {
  color: #7e7e7e;
  font-size: 24px !important;
}
.home .advertiser-company-ad .advertiser-company-ad-body-title .advertiser-company-ad-imgs {
  height: 305px;
  max-width: 305px;
}
.home .advertiser-company-ad .advertiser-company-ad-body-title .advertiser-company-ad-imgs .carousel-root .slider {
  margin-left: auto;
  margin-right: auto;
}
.home .advertiser-company-ad .advertiser-company-ad-body-title .advertiser-company-ad-imgs .carousel-root .dot {
  width: 15px;
  height: 15px;
}
.home .advertiser-company-ad .advertiser-company-ad-body-title .advertiser-company-ad-imgs .carousel-root .carousel-status {
  font-size: x-large;
}
.home .advertiser-company-ad .advertiser-company-ad-body-title .advertiser-company-ad-imgs .company-ad-slider-imgs,
.home .advertiser-company-ad .advertiser-company-ad-body-title .advertiser-company-ad-imgs .company-ad-img {
  height: 305px;
  min-width: 305px;
}
.home .advertiser-company-ad .advertiser-name-logo {
  background-color: white;
  max-height: 63px;
  padding: 10px;
  border-radius: 10px;
  max-width: fit-content;
}
.home .advertiser-company-ad .advertiser-name-logo .advertiser-logo-img {
  max-width: 190px;
  max-height: 50px;
}
.home .advertiser-association-ad {
  background-color: #f5f5f5;
  cursor: pointer;
}
.home .advertiser-association-ad .type {
  color: #7e7e7e;
  font-size: 24px !important;
}
.home .advertiser-association-ad .advertiser-association-ad-body-title .advertiser-association-ad-title {
  font-size: 38px !important;
}
.home .advertiser-association-ad .advertiser-association-ad-body-title .advertiser-association-ad-body {
  /*  font-size: 28px !important; */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.home .advertiser-association-ad .advertiser-association-ad-body-title .advertiser-association-ad-imgs {
  min-width: 228px;
  max-height: 235px;
}
.home .advertiser-association-ad .advertiser-association-ad-body-title .advertiser-association-ad-imgs .carousel-root .slider {
  margin-left: auto;
  margin-right: auto;
}
.home .advertiser-association-ad .advertiser-association-ad-body-title .advertiser-association-ad-imgs .carousel-root .dot {
  width: 15px;
  height: 15px;
}
.home .advertiser-association-ad .advertiser-association-ad-body-title .advertiser-association-ad-imgs .carousel-root .carousel-status {
  font-size: x-large;
}
.home .advertiser-association-ad .advertiser-association-ad-body-title .advertiser-association-ad-imgs .association-ad-slider-imgs,
.home .advertiser-association-ad .advertiser-association-ad-body-title .advertiser-association-ad-imgs .association-ad-img {
  max-width: 228px;
  min-height: 235px;
}
.home .advertiser-association-ad .advertiser-name-logo {
  background-color: white;
  max-height: 63px;
  padding: 10px;
  border-radius: 10px;
  max-width: fit-content;
}
.home .advertiser-association-ad .advertiser-name-logo .advertiser-name {
  font-size: 30px !important;
}
.home .advertiser-association-ad .advertiser-name-logo .advertiser-logo-img {
  max-width: 190px;
  max-height: 50px;
}
.home .advertiser-internal-ad .advertiser-internal-ad-title-body .body {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.home .advertiser-internal-ad .advertiser-internal-ad-img {
  max-width: 228px;
}/*# sourceMappingURL=Home.css.map */
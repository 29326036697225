@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    /* list-style: none; */
    text-decoration: none !important;
    font-family: 'Source Sans Pro', sans-serif;
}

:root {
    /* Colors: */
        --unnamed-color-242424: #242424;
    
        /* Font/text values */
        --unnamed-font-family-source-sans-pro: Source Sans Pro;
        --unnamed-font-style-normal: normal;
        --unnamed-font-weight-bold: bold;
        --unnamed-font-size-36: 36px;
        --unnamed-character-spacing-0: 0px;
        --unnamed-line-spacing-45: 45px;
        --unnamed-text-transform-uppercase: uppercase;

    }
    
    /* Character Styles */
    .unnamed-character-style-1 {
        font-family: var(--unnamed-font-family-source-sans-pro);
        font-style: var(--unnamed-font-style-normal);
        font-weight: var(--unnamed-font-weight-bold);
        font-size: var(--unnamed-font-size-36);
        line-height: var(--unnamed-line-spacing-45);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-242424);
        text-transform: var(--unnamed-text-transform-uppercase);
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
  /*  display: none; */
}

a {
    color: black !important;
}

.hidden {
    display: none;
}

.byline {
    flex: content;

    .byline_img {
        img {
            width: 100px;
            height: 100px;
            object-fit: cover;
        }
    }
}
.pool-temp .events-header {
  position: relative;
}
.pool-temp .events-header .events-header-title {
  position: absolute;
  left: 65%;
  top: 35%;
}
.pool-temp .line {
  clear: both;
  color: black !important;
  height: 10px;
}/*# sourceMappingURL=PoolTemperature.css.map */
.reader-news-details {
  height: 2141px;
  overflow: auto;
  .reader-news-date, .reader-news-details-header-text {
    color: #707070;
  }
  .advertiser-details-ad-title {
    font-size: 56px !important;
  }
}
